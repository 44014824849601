export const PostHogEvents = {
  SearchUpdate: "search_update",
  CallToActionClicked: "cta_clicked",
  LearningResourceDrawerOpen: "lrd_open",
  LearningResourceDrawerView: "lrd_view",
  HeroBrowseTopics: "clicked_hero_browse_topics",
  LRAddToList: "lr_add_to_list",
  ProviderLinkClicked: "provider_link_clicked",
  HomeTopicClicked: "home_topic_clicked",
  HomeSeeAllTopicsClicked: "home_see_all_topics_clicked",
  TopicClicked: "topic_clicked",
  SubTopicClicked: "sub_topic_clicked",
  RelatedTopicClicked: "related_topic_clicked",
  DepartmentLinkClicked: "department_link_clicked",
  ClosedNavDrawer: "closed_nav_drawer",
  OpenedNavDrawer: "opened_nav_drawer",
  ClickedNavBrowseCourses: "clicked_nav_browse_courses",
  ClickedNavBrowsePrograms: "clicked_nav_browse_programs",
  ClickedNavBrowseLearningMaterials: "clicked_nav_browse_learning_materials",
  ClickedNavBrowseTopics: "clicked_nav_browse_topics",
  ClickedNavBrowseDepartments: "clicked_nav_browse_departments",
  ClickedNavBrowseProviders: "clicked_nav_browse_providers",
  ClickedNavBrowseNew: "clicked_nav_browse_new",
  ClickedNavBrowseUpcoming: "clicked_nav_browse_upcoming",
  ClickedNavBrowsePopular: "clicked_nav_browse_popular",
  ClickedNavBrowseFree: "clicked_nav_browse_free",
  ClickedNavBrowseCertificate: "clicked_nav_browse_certificate",
} as const
